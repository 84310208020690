import React from 'react';
import Isotope from 'isotope-layout';
import popup from "../../../assest/images/popop-img.png";
import loader from "../../../assest/images/loader.gif"

export const Modal = ({data}) => {
      console.log(data);
    return (
      <>
     <div className="modal fade modal_section" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content">
    
      <div className="modal-body">
      <div className="popup-bann">
        <img src={popup} alt="popup"/>
       </div>
            <div className="popup-content">
               <h2>Get Started Now</h2>
               <div className='' style={{position: "relative"}}>
               <button type="butbuttonton" className="fancybox-button fancybox-close-small" data-bs-dismiss="modal"><i class="fa fa-close"></i></button>
               </div>
               <p>Providing you the perfect solution for your business needs. Let's work together and unlock doors to success.</p>
               <form method="POST" className="form_submission">

                  {/* <!-- Custom Fields --> */}
                  <input type="hidden" name="url" value=''/>
                  <input type="hidden" name="domain" value=""/>
                  <input type="hidden" name="subject" value=""/>
                  <input type="hidden" name="price" data-name="price" value=""/>
                  <input type="hidden" name="title" data-name="title" value=""/>
                 <input type="hidden" name="pkgctgry" data-name="pkgctgry" value=""/>
                <input type="hidden" name="pkgtitle" data-name="pkgtitle" value=""/>
                  

                  {/* <!--PPC parameters --> */}
                  <input type="hidden" name="keyword" value=""/>
                  <input type="hidden" name="matchtype" value=""/>
                  <input type="hidden" name="msclkid" value=""/>
                  <input type="hidden" name="gclid" value=""/>
                {/* //   <!--End PPC paramters --> */}

                  <div className="row">
                     <div className="col-md-6">
                        <input type="text" name="name" placeholder="Your Name" required=""/>

                        <input type="email" className="email" name="email" placeholder="Your Email" required=""/>

                        <input type="number" className="number" name="phone" minlength="10" maxlength="12" placeholder="Your Phone" required=""/>
                     </div>
                     <div className="col-md-6">
                        <textarea autocomplete="nope" name="Message" placeholder="To help us understand better, enter a brief description about your project."></textarea>
                     </div>
                     <div className="col-md-12 text-center pop-btn-box">
                        <input type="submit" className="btn btn-blue" name="submit" value="Submit Now"/>
                        {/* <div className="error mt-3 alert alert-danger text-left mb-0" ></div>
                        <div className="success mt-3 alert alert-success text-left mb-0" ></div>
                        <div className="loader" >
                           <img alt="loader" src={loader}/>
                        </div> */}
                        
                     </div>
                  </div>
               </form>
            </div>
      </div>
    </div>
  </div>
</div>

{/* <button className="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Open first modal</button> */}
      </>
    )
  }
