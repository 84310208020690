import React, { useState } from "react";
import {
  valueData,
  webs,
  videos,
  stationary,
  illustration,
  apps,
} from "../../../data";
import Isotope from "isotope-layout";
// import logo78 from "../assest/images/logo/78.jpg";
import gif1 from "../../../assest/images/animated-logo/01.gif";
import web1 from "../../../assest/images/web/01.jpg";

export const Portfolio = () => {
  const [activeButton, setActiveButton] = useState("logo"); // Default to the first button
  const [getData, setGetData] = useState(valueData); // Start with logos
  const [displayedImages, setDisplayedImages] = useState(getData.slice(0, 6)); // Initially, show the first 6 images
  const [startIndex, setStartIndex] = useState(6);

  // init one ref to store the future isotope object
  const isotope = React.useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = React.useState(".logo");
  let [displayImagesSection, setDisplayImagesSection] = useState(
    valueData.slice(0, 6)
  );
  let [getFilter, setGetFilter]=useState("")

  // initialize an Isotope object with configs
  React.useEffect(() => {
    isotope.current = new Isotope(".filter-container", {
      itemSelector: ".filter-item",
      layoutMode: "fitRows",
    });
    // cleanup
    return () => isotope.current.destroy();
  }, []);

  // handling filter key change
  React.useEffect(() => {
    filterKey === ".logo"
      ? isotope.current.arrange({ filter: `.logo` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  // const handleFilterKeyChange = key => () => setFilterKey(key)

  const handleFilterKeyChange = (filter) => {
 
    const str = filterKey;
    const newStr = str.slice(1);
    setGetFilter(newStr);

    console.log(filterKey,filter);
    setFilterKey(filter);
    // Depending on the active button, set the appropriate data array.
    switch (filter) {
      case "web":
        setDisplayImagesSection(webs.slice(0, 6));
        break;
      case "logo":
        setDisplayImagesSection(valueData.slice(0, 6));
        break;
      // case "video":
      //   setDisplayImagesSection(videos.slice(0, 6));
      //   break;
      case "branding":
        setDisplayImagesSection(stationary.slice(0, 6));
        break;
      case "illustration":
        setDisplayImagesSection(illustration.slice(0, 6));
        break;
      case "apps":
        setDisplayImagesSection(apps.slice(0, 6));
        break;
      default:
        // Default to 'logo'
        setDisplayImagesSection(valueData.slice(0, 6));
    }
  };

  const showNextImages = () => {
    const endIndex = startIndex + 6;

    // Depending on the active category, update the displayed images from the appropriate data array
    switch (filterKey) {
      case "web":
        setDisplayedImages([
          ...displayedImages,
          ...webs.slice(startIndex, endIndex),
        ]);
        break;
      case "logo":
        setDisplayedImages([
          ...displayedImages,
          ...valueData.slice(startIndex, endIndex),
        ]);
        break;
      case "video":
        setDisplayedImages([
          ...displayedImages,
          ...videos.slice(startIndex, endIndex),
        ]);
        break;
      case "branding":
        setDisplayedImages([
          ...displayedImages,
          ...stationary.slice(startIndex, endIndex),
        ]);
        break;
      case "illustration":
        setDisplayedImages([
          ...displayedImages,
          ...illustration.slice(startIndex, endIndex),
        ]);
        break;
      case "apps":
        setDisplayedImages([
          ...displayedImages,
          ...apps.slice(startIndex, endIndex),
        ]);
        break;
      default:
        // Default to 'logo'
        setDisplayedImages([
          ...displayedImages,
          ...valueData.slice(startIndex, endIndex),
        ]);
    }
    setStartIndex(endIndex);
  };
  const getMoreImages = () => {
    const endIndex = startIndex + 6;
    switch (filterKey) {
      case "web":
        setDisplayImagesSection([
          ...displayImagesSection,
          ...webs.slice(startIndex, endIndex),
        ]);
        break;
      case "logo":
        setDisplayImagesSection([
          ...displayImagesSection,
          ...valueData.slice(startIndex, endIndex),
        ]);
        break;
        case "branding":
          setDisplayImagesSection([
            ...displayImagesSection,
            ...stationary.slice(startIndex, endIndex),
          ]);
          break;
        case "illustration":
          setDisplayImagesSection([
            ...displayImagesSection,
            ...illustration.slice(startIndex, endIndex),
          ]);
          break;
        case "apps":
          setDisplayImagesSection([
            ...displayImagesSection,
            ...apps.slice(startIndex, endIndex),
          ]);
          break;
      default:
        setDisplayImagesSection([
          ...displayImagesSection,
          ...valueData.slice(startIndex, endIndex),
        ]);
    }
    setStartIndex(endIndex);
  };

  return (
    <div>
      <section className="mainpf  our-portfolio">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <h6>Our Recent</h6>
              <h2>Work</h2>
            </div>
            <div className="col-md-10">
              <p>
                We, being one of the leading logo design companies offers you a
                chance to hire a logo designer who can understand your company's
                core message and ideology and world according to your business
                needs. We pour the utmost creativity and bring out the appeal
                with our incredible techniques. We delve deeper into the market
                to look for aspects that can sketch an authoritative image of
                the companies. The secret to increasing the overall conversion
                lies in the creative display of skills and that what we promise
                at our premier logo design company.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div id="filters" className="button-group filters-button-group li_section">
            <li
              className={`button ${
                filterKey === ".logo" ? "is-checked filter-enb" : "filter-dis"
              }`}
              data-filter=".logo"
              onClick={() => handleFilterKeyChange("logo")}
            >
              Logos
            </li>
            <li
              className={`button ${
                filterKey === ".web" ? "is-checked filter-enb" : "filter-dis"
              }`}
              data-filter=".web"
              onClick={() => handleFilterKeyChange("web")}
            >
              Websites
            </li>
            {/* <li
              className={`button ${
                activeButton === "video"
                  ? "is-checked filter-enb"
                  : "filter-dis vd-random"
              }`}
              data-filter=".video"
              onClick={handleFilterKeyChange("video")}
            >
              Video
            </li> */}
            <li
              className={`button ${
                filterKey === ".branding"
                  ? "is-checked filter-enb"
                  : "filter-dis"
              }`}
              data-filter=".branding"
              onClick={() =>  handleFilterKeyChange("branding")}
            >
              Stationery
            </li>
            <li
              className={`button ${
                filterKey === ".illustration"
                  ? "is-checked filter-enb"
                  : "filter-dis"
              }`}
              data-filter=".illustration"
              onClick={() =>  handleFilterKeyChange("illustration")}
            >
              Illustration
            </li>
            <li
              className={`button ${
                filterKey === ".apps" ? "is-checked filter-enb" : "filter-dis"
              }`}
              data-filter=".apps"
              onClick={() =>  handleFilterKeyChange("apps")}
            >
              Mobile Apps
            </li>
          </div>

          {/* <div className="grid portfolio_section">
            {activeButton == "video" ? (
              displayedImages.map((video) => (
                <div
                  className={`element-item col-md-4 ${activeButton} `}
                  key={video.id}
                  data-category="video"
                >
                  <a href={video.videoLink} data-fancybox="video">
                    <img src={video.videoImg} className="lazy" />
                  </a>
                </div>
              ))
            ) : (
              // displayedImages.map((image) => (
              //   <div
              //     className={`element-item filter-item col-md-4 ${filterKey}`}
              //     key={image.id}
              //     category={`${filterKey}`}
              //   >
              //   <img src={image.img} alt={`Image ${image.id + 1}`} className="lazy" />
              //   </div>
              // ))
              <></>
            )}
          </div> */}
          <ul>
           
           
          </ul>
          <hr />
          {filterKey == ".logo" ? (
    <div className="filter-container logo_section">
    <div className={`filter-item logo`}>
      {displayImagesSection.map((value) => {
        return (
          <div
            class="col-md-4 element-item logo"
            key={value.id}
            category="logo"
          >
            <img src={value.img} class="lazy" alt="" />
          </div>
        );
      })}
    </div>
   
  </div>
          ):
          <>
          <div className={`filter-item ${getFilter} `}>
       {displayImagesSection.map((value) => {
        return (
          <div
            class={`col-md-4 element-item ${getFilter}`}
            key={value.id}
            category={`${getFilter}`}
          >
            <img src={value.img} class="lazy" alt="" />
          </div>
        );
      })}
    </div>
          </> 
          }
       {displayImagesSection.length && (
              <button onClick={getMoreImages} id="showMore"></button>
            )}
        </div>
      </section>
    </div>
  );
};
