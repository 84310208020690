import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { Ctagroup } from '../../../../component/home'
import {Packages} from "../../../../component/packages";

export const PricingPackages = () => {
  return (
    <div>
        <MainHeader/>
        <Packages/>
        <Ctagroup/>
        <MainFooter/>
        
    </div>
  )
}
