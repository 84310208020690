import React from 'react';
import {   DescComp } from '../../../component/lp/desccomp';
import { Portfolio } from '../../../component/lp/portfolio';
import { TypeLogo } from '../../../component/lp/typelogo';
import { Header } from '../../lpHeader';
import { Client } from '../../../component/lp/clients';
import { Footer } from '../../footer';
import { BannerSection } from '../../../component/lp/bannerSection';
import {AboutComp} from "../../../component/lp/AboutComp";
import {Service} from "../../../component/lp/service";
import {Pricing} from "../../../component/lp/pricing";
import {Cards} from "../../../component/lp/cards";
import {Concepts} from "../../../component/lp/concepts"
import "./index.css";

export const LandingPage = () => {
  return (
    <div>
      <Header />
      <BannerSection/>
      <Service/>
      <Portfolio/>
      <AboutComp/>
      <Pricing/>
      <Cards/>
      <TypeLogo/>
      <DescComp/>
      <Concepts/>
      <Client/>
      <Footer/>
    </div>
  )
}
