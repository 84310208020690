import React from 'react'
import dark_img from "../../../assest/cta-img.webp"

export const AboutComp = () => {
  return (
    <div>
    
    <section className="cta-sec lazy-background">
        <div className="container">
            <div className="row">
                <div className="col-md-10" data-aos="fade-down" data-aos-duration="1500">
                    <div className="cta-wrpr">
                        <h2>Make A Lasting Impression<br/>
                            With A Professionally Crafted Logo
                            That Sets <br/>  Your Business Apart From Competitors.</h2>
                        <p>Hire dummy name and get a 70% discount on your logo projects.</p>
                        <div className="header-button cta-buttons">
                            <a className="openpopup hvr-buzz-out dft-btn" href="javascript:void(0);">Get Started Now</a> <a
                                className="chat hvr-buzz-out dft-btn" href="javascript:void(0);">Live Chat</a>
                        </div>
                        <p>Or Talk To An Expert: <a href="#">1234567897</a>
                        </p>
                    </div>
                </div>
                <img className="cta-img"  src={dark_img} alt='dark_img' style={{width: "auto", zIndex: "99"}} />
            </div>
        </div>
    </section>

    </div>
  )
}
