import React, {useState} from 'react'
import home_slide1 from "../../../assest/images/home-slide-1.png";
import home_slide2 from "../../../assest/images/home-slide-2.png";
import home_slide3 from "../../../assest/images/home-slide-3.png";
import Slider from "react-slick";
// import { Modal } from '../../../layout/modal'; // Import the Modal component


export const SliderSection = () => {
    let settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
     };

     const [isModalVisible, setModalVisible] = useState(false);

     const openModal = () => {
       setModalVisible(true);
     };
   
     const closeModal = () => {
       setModalVisible(false);
     };
     const modalStyle = {
      display: isModalVisible ? 'block' : 'none'
    };
  return (
    <div>
         <section className="home-banner">
            <div className="container">
               <div className="caption">
                  <div className="bxslider home_page">
                     <Slider {...settings}>
                        <div>
                           <div className="row">
                              <div className="col-md-6">
                                 <h1>Innovative Logo Design &amp; Branding Solutions</h1>
                                 
                                 <p>We are committed towards providing professional logo designs tailored to your brand’s culture, core values & distinction.</p>
                                 <button onClick={openModal}   className="various btn btn-blue openpopup">Start Project</button>


                                 {/* <a data-fancybox="" title="Get Started" data-src="#popupform" href="javascript:;" className="various btn btn-blue openpopup">Start Project</a> */}
                              </div>
                              <div className="col-md-6">
                                 <img src={home_slide1} alt="" className="img1" />
                              </div>
                           </div>
                        </div>
                        <div>
                           <div className="row">
                              <div className="col-md-6">
                                 <h1>Digital Experiences Engineered to Perform</h1>
                                 <p>We take an integrated approach to creating highly engaging digital properties & brand focused creative solutions.</p>
                                 <a data-fancybox="" title="Get Started" data-src="#popupform" href="javascript:;" className="various btn btn-blue openpopup">Start Project</a>
                              </div>
                              <div className="col-md-6">
                                 <img src={home_slide2} alt="" className="img2" />
                              </div>
                           </div>
                        </div>
                        <div>
                           <div className="row">
                              <div className="col-md-6">
                                 <h1>Get Your Prospects <br /> to Say Yes!</h1>
                                 <p>If pictures speaks louder than words, videos speak even louder. We produce inspirational animated videos for businesses globally.</p>
                                 <a data-fancybox="" title="Get Started" data-src="#popupform" href="javascript:;" className="various btn btn-blue openpopup">Start Project</a>
                              </div>
                              <div className="col-md-6">
                                 <img src={home_slide3} alt="" className="img3" />
                              </div>
                           </div>
                        </div>
                     </Slider>
                  </div>
               </div>
            </div>
         </section>

    </div>
  )
}
