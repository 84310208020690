import React from 'react';
import black_logo from "../../assest/images/logo-black.png";
import phonecrice from "../../assest/images/phone-circle.png";
import { NavLink } from 'react-router-dom';
import { Modal } from '../../component/home/check'; 

export const MainHeader = () => {

   return (
      <div>
         <header>
            <div className="container-fluid">
               <div className="row align-items-center">
                  <div className="col-md-3">
                     <NavLink to="/" className="logo">
                        <img src={black_logo} alt="black_logo" />
                     </NavLink>
                  </div>

                  <div className="col-md-9">
                     <nav className="navbar-expand-md main-menu">
                        <ul className="menu main_header">
                           <li><a href="/" ><span>01</span>Home</a></li>
                           <li className="nav-item dropdown">
                           <div className='section_link'>

                              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                 <span className="navbar-toggler-icon"></span>
                              </button>
                              <div className="" id="navbarNavDropdown">
                                 <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                       <NavLink className="nav-link dropdown-toggle" href="javascript:" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span>02</span>Services</NavLink>
                                    </li>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                       <li><a href="logo-design.php">Logo Design</a></li>
                                       <li><a href="website-design.php">Website</a></li>
                                       <li><a href="stationery.php">Stationery</a></li>
                                       <li><a href="video-animation.php">Video Animation</a></li>
                                       <li><a href="seo.php">SEO</a></li>
                                       <li><a href="content-writing.php">Content Writing</a></li>
                                       <li className="last"><a href="social-media-design.php">Social Media Design</a></li>
                                    </ul>
                                 </ul>
                              </div>
                              <li><NavLink to="/portfolio" ><span>03</span>Work</NavLink></li>
                              <li><NavLink to="/packages" ><span>04</span>Pricing</NavLink></li>
                              <li><a href="about.php"><span>05</span>About</a></li>
                              <li><a href="contact.php"><span>06</span>Contact</a></li>

                              <li className="phone">
                                 <img src={phonecrice} alt="phonecrice" />
                                 <a href="<?php echo PHONE_HREF ?>">(855) 535-2384</a>
                              </li>
                              </div>
                              <li className="me-btn">
                              <button className="various btn btn-blue openpopup" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Get Started</button>
                                 {/* <a data-fancybox="" title="Get Started" data-src="#popupform" href="javascript:;" className="various btn btn-blue openpopup">Get Started</a> */}
                                 <a href="javascript:;" className="chat various btn btn-blue">Live Chat</a>
                              </li>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </header>
         
  <Modal/>

      </div>
   )
}
