import React from 'react';
import our_category from "../../../assest/images/our-category-img.jpg";
import Slider from "react-slick";


export const OurCategory = () => {

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
      };


  return (
    <div>
        <section className="our-category">
         <div className="container-fluid">
            <div className="row">
               <div className="col-md-8">
                  <img src={our_category} alt="our_category"/>
               </div>
               <div className="col-md-4">
                  <div className="txt">
                     <h2>Transforming your vision into digital reality</h2>
                     <p>Our company philosophy at Go To Logo Experts is to value authenticity; this leads us to continually draw feedback from our clients through the development process. With regular open communication, the designs we craft embody our client’s vision and respond to the latest developments in market trends; our two-way approach helps simultaneously satisfy our clients and ensure growth in their market footprint which further increases their outreach to a bigger audience.</p>
                  </div>
               </div>
            </div>
         </div>
         <div className="container slick_carosals">
            <div className="ser-slider">
               <div className="owl-service owl-carousel owl-theme">
               <Slider {...settings}>     
                  <div className="item">
                     <h4>Logo Design</h4>
                     <p>Our custom logo designers are specialized in creating logos of all categories.</p>
                  </div>
                  <div className="item">
                     <h4>Website</h4>
                     <p>Our designers create responsive website designs that work on any platform.</p>
                  </div>
                  <div className="item">
                     <h4>Stationery</h4>
                     <p>Our alluring and specialized designs will improve your prospective clients and make your product stand apart.</p>
                  </div>
                  <div className="item">
                     <h4>Video Animation</h4>
                     <p>Bring Exclusivity to your brand with awe-Inspiring animations that inspire instantly.</p>
                  </div>
                  <div className="item">
                     <h4>Digital Marketing</h4>
                     <p>A digital agency that incorporates creative campaigns, innovative technology, and accurate analytics.</p>
                  </div>
                  <div className="item">
                     <h4>Mobile Applications</h4>
                     <p>Provide value to your customer through our custom mobile applications.</p>
                  </div>
              </Slider>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
