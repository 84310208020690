import React from 'react';
import port1 from "../../../assest/port-1.webp"
import port2 from "../../../assest/port-2.webp"
import port3 from "../../../assest/port-3.webp"
import port4 from "../../../assest/port-4.webp"
import port5 from "../../../assest/port-5.webp"
import port6 from "../../../assest/port-6.webp"
import port7 from "../../../assest/port-7.webp"
import port8 from "../../../assest/port-8.webp"
import port9 from "../../../assest/port-9.webp"
import port10 from "../../../assest/port-10.webp"
import port11 from "../../../assest/port-11.webp"
import port12 from "../../../assest/port-12.webp"
import port13 from "../../../assest/port-13.webp"
import port14 from "../../../assest/port-14.webp"
import port15 from "../../../assest/port-15.webp"
import port16 from "../../../assest/port-16.webp"
import port17 from "../../../assest/port-17.webp"
import port18 from "../../../assest/port-18.webp"
import Slider from "react-slick";



var settings = {
  dots: true,
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear"
};

var settingsTwo = {
    dots: true,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: -1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };

export const Portfolio = () => {
  return (
    <div>
    <section className="portfolio-sec lazy-background">
        <div className="container-fluid p-0 text-center">
            <div className="section-heading" data-aos="fade-down" data-aos-duration="1500">
                <h3>Our Portfolio</h3>
                <p>We know the latest industry trends and leverage it to our advantage to design you an <br/>
                    industry-specific logo for your brand!</p>
            </div>
            <ul className="portfolio-logo portfolio1 owl-carousel owl-theme slider_ul" data-aos="fade-up" data-aos-duration="1500">
                <Slider {...settings}>
                <li>
                    <img className=" " src={port1} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port2} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port3} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port4} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port5} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port6} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port7} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port8} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port9} alt='port1' width={"auto"} height={"auto"}/>
                </li>
                </Slider>
            </ul>
            <ul className="portfolio-logo portfolio2 owl-rtl owl-carousel owl-theme mt-5 slider_ul" data-aos="fade-up"
                data-aos-duration="1500">
                                    <Slider {...settingsTwo}>

                <li>
                    <img className=" " src={port10} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port11} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port12} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port13} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port14} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port15} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port16} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port17} alt='port' width={"auto"} height={"auto"}/>
                </li>
                <li>
                    <img className=" " src={port18} alt='port' width={"auto"} height={"auto"}/>
                </li>
                </Slider>
            </ul>
            <a href="javascript:void(0);" className="btn bnr-btn hvr-buzz-out openpopup portfolio_btn" data-aos="fade-up"
                data-aos-duration="1500">
                Let’s Get Started
            </a>
        </div>
    </section>

    </div>
  )
}
