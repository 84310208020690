import React from 'react';
import letsTalk from "../../../assest/images/lets-talk-img.jpg"

export const Ctagroup = () => {
  return (
    <div>
        <section className="lets-talk">
    <div className="container">
        <div className="row">
            <div className="col-md-3">
                <img src={letsTalk} alt="letsTalk"/>
            </div>
            <div className="col-md-7">
                <h2>Ready to work with us?</h2>
                <p>Speak with one of our Design Consultants about your or general inquiries. Get in touch with us and one of our consultants will be with you shortly.</p>
                <a href="javascript:;" data-fancybox data-src="#popupform" className="openpopup various btn btn-grey">Get Started</a>
                <a href="javascript:" className="btn btn-blue chat">Live Chat</a>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}
