import gif1 from "../assest/images/animated-logo/01.gif";
import gif2 from "../assest/images/animated-logo/02.gif";
import gif3 from "../assest/images/animated-logo/03.gif";
import gif4 from "../assest/images/animated-logo/06.gif";
import gif5 from "../assest/images/animated-logo/07.gif";
import gif6 from "../assest/images/animated-logo/12.gif";
import gif7 from "../assest/images/animated-logo/17.gif";
import gif8 from "../assest/images/animated-logo/18.gif";
import logo1 from "../assest/images/logo/01.jpg";
import logo2 from "../assest/images/logo/02.jpg";
import logo3 from "../assest/images/logo/03.jpg";
import logo4 from "../assest/images/logo/04.jpg";
import logo5 from "../assest/images/logo/05.jpg";
import logo6 from "../assest/images/logo/06.jpg";
import logo7 from "../assest/images/logo/07.jpg";
import logo8 from "../assest/images/logo/08.jpg";
import logo9 from "../assest/images/logo/09.jpg";
import logo10 from "../assest/images/logo/10.jpg";
import logo11 from "../assest/images/logo/11.jpg";
import logo12 from "../assest/images/logo/12.jpg";
import logo13 from "../assest/images/logo/13.jpg";
import logo14 from "../assest/images/logo/14.jpg";
import logo15 from "../assest/images/logo/15.jpg";
import logo16 from "../assest/images/logo/16.jpg";
import logo17 from "../assest/images/logo/17.jpg";
import logo18 from "../assest/images/logo/18.jpg";
import logo19 from "../assest/images/logo/19.jpg";
import logo20 from "../assest/images/logo/20.jpg";
import logo21 from "../assest/images/logo/21.jpg";
import logo22 from "../assest/images/logo/22.jpg";
import logo23 from "../assest/images/logo/23.jpg";
import logo24 from "../assest/images/logo/24.jpg";
import logo25 from "../assest/images/logo/25.jpg";
import logo26 from "../assest/images/logo/26.jpg";
import logo27 from "../assest/images/logo/27.jpg";
import logo28 from "../assest/images/logo/28.jpg";
import logo29 from "../assest/images/logo/29.jpg";
import logo30 from "../assest/images/logo/30.jpg";
import logo31 from "../assest/images/logo/31.jpg";
import logo32 from "../assest/images/logo/32.jpg";
import logo33 from "../assest/images/logo/33.jpg";
import logo34 from "../assest/images/logo/34.jpg";
import logo35 from "../assest/images/logo/35.jpg";
import logo36 from "../assest/images/logo/36.jpg";
import logo37 from "../assest/images/logo/37.jpg";
import logo38 from "../assest/images/logo/38.jpg";
import logo39 from "../assest/images/logo/39.jpg";
import logo40 from "../assest/images/logo/40.jpg";
import logo41 from "../assest/images/logo/41.jpg";
import logo42 from "../assest/images/logo/42.jpg";
import logo43 from "../assest/images/logo/43.jpg";
import logo44 from "../assest/images/logo/44.jpg";
import logo45 from "../assest/images/logo/45.jpg";
import logo46 from "../assest/images/logo/46.jpg";
// import logo47 from "../assest/images/logo/4";
import logo48 from "../assest/images/logo/48.jpg";
import logo49 from "../assest/images/logo/49.jpg";
import logo50 from "../assest/images/logo/50.jpg";
import logo51 from "../assest/images/logo/51.jpg";
import logo52 from "../assest/images/logo/52.jpg";
import logo53 from "../assest/images/logo/53.jpg";
import logo54 from "../assest/images/logo/54.jpg";
import logo55 from "../assest/images/logo/55.jpg";
import logo56 from "../assest/images/logo/56.jpg";
import logo57 from "../assest/images/logo/57.jpg";
import logo58 from "../assest/images/logo/58.jpg";
import logo59 from "../assest/images/logo/59.jpg";
import logo60 from "../assest/images/logo/60.jpg";
import logo61 from "../assest/images/logo/61.jpg";
import logo62 from "../assest/images/logo/62.jpg";
import logo63 from "../assest/images/logo/63.jpg";
import logo64 from "../assest/images/logo/64.jpg";
import logo65 from "../assest/images/logo/65.jpg";
import logo66 from "../assest/images/logo/66.jpg";
import logo67 from "../assest/images/logo/67.jpg";
import logo68 from "../assest/images/logo/68.jpg";
import logo69 from "../assest/images/logo/69.jpg";
import logo70 from "../assest/images/logo/70.jpg";
import logo71 from "../assest/images/logo/71.jpg";
import logo72 from "../assest/images/logo/72.jpg";
import logo73 from "../assest/images/logo/73.jpg";
import logo74 from "../assest/images/logo/74.jpg";
import logo75 from "../assest/images/logo/75.jpg";
import logo76 from "../assest/images/logo/76.jpg";
import logo78 from "../assest/images/logo/78.jpg";



import web1 from "../assest/images/web/01.jpg";
import web2 from "../assest/images/web/02.jpg";
import web3 from "../assest/images/web/03.jpg";
import web4 from "../assest/images/web/04.jpg";
import web5 from "../assest/images/web/05.jpg";
import web6 from "../assest/images/web/06.jpg";
import web7 from "../assest/images/web/07.jpg";
import web8 from "../assest/images/web/08.jpg";
import web9 from "../assest/images/web/09.jpg";
import web10 from "../assest/images/web/10.jpg";
import web11 from "../assest/images/web/11.jpg";
import web13 from "../assest/images/web/13.jpg";
import web14 from "../assest/images/web/14.jpg";
import web15 from "../assest/images/web/15.jpg";
import web16 from "../assest/images/web/16.jpg";
import web17 from "../assest/images/web/17.jpg";
import web18 from "../assest/images/web/18.jpg";
import web19 from "../assest/images/web/19.jpg";
import web20 from "../assest/images/web/20.jpg";
import web21 from "../assest/images/web/21.jpg";
import web22 from "../assest/images/web/22.jpg";
import web23 from "../assest/images/web/23.jpg";
import web24 from "../assest/images/web/24.jpg";
import web25 from "../assest/images/web/25.jpg";
import web26 from "../assest/images/web/26.jpg";
import web27 from "../assest/images/web/27.jpg";
import web28 from "../assest/images/web/28.jpg";
import web29 from "../assest/images/web/29.jpg";
import web30 from "../assest/images/web/30.jpg";
import web31 from "../assest/images/web/31.jpg";
import web32 from "../assest/images/web/32.jpg";
import web33 from "../assest/images/web/33.jpg";
import web34 from "../assest/images/web/34.jpg";
import web35 from "../assest/images/web/35.jpg";
import web36 from "../assest/images/web/36.jpg";
import web37 from "../assest/images/web/37.jpg";
import web38 from "../assest/images/web/38.jpg";
import web39 from "../assest/images/web/39.jpg";
import web41 from "../assest/images/web/41.jpg";
import web42 from "../assest/images/web/42.jpg";
import web43 from "../assest/images/web/43.jpg";
import web44 from "../assest/images/web/44.jpg";
import web45 from "../assest/images/web/45.jpg";
import web46 from "../assest/images/web/46.jpg";
import web47 from "../assest/images/web/47.jpg";
import web48 from "../assest/images/web/48.jpg";
import web49 from "../assest/images/web/49.jpg";

import videoImg1 from "../assest/images/video-animation/01.webp";
import videoImg2 from "../assest/images/video-animation/02.webp";
import videoImg3 from "../assest/images/video-animation/03.webp";
import videoImg4 from "../assest/images/video-animation/04.webp";
import videoImg5 from "../assest/images/video-animation/05.webp";
import videoImg6 from "../assest/images/video-animation/06.webp";
import videoImg7 from "../assest/images/video-animation/07.webp";
import videoImg8 from "../assest/images/video-animation/08.webp";
import videoImg10 from "../assest/images/video-animation/10.webp";

import videoLink1 from "../assest/images/video-animation/1.mp4";
import videoLink2 from "../assest/images/video-animation/2.mp4";
import videoLink3 from "../assest/images/video-animation/3.mp4";
import videoLink4 from "../assest/images/video-animation/4.mp4";
import videoLink5 from "../assest/images/video-animation/5.mp4";
import videoLink6 from "../assest/images/video-animation/6.mp4";
import videoLink7 from "../assest/images/video-animation/7.mp4";
import videoLink8 from "../assest/images/video-animation/8.mp4";
import videoLink10 from "../assest/images/video-animation/10.mp4";


import stationary1 from "../assest/images/branding/01.jpg";
import stationary2 from "../assest/images/branding/02.jpg";
import stationary3 from "../assest/images/branding/03.jpg";
import stationary4 from "../assest/images/branding/04.jpg";
import stationary5 from "../assest/images/branding/05.jpg";
import stationary6 from "../assest/images/branding/06.jpg";
import stationary7 from "../assest/images/branding/07.jpg";
import stationary8 from "../assest/images/branding/08.jpg";
import stationary9 from "../assest/images/branding/09.jpg";



import illustration1 from "../assest/images/illustration/01.jpg";
import illustration2 from "../assest/images/illustration/02.jpg";
import illustration3 from "../assest/images/illustration/03.jpg";
import illustration4 from "../assest/images/illustration/04.jpg";
import illustration5 from "../assest/images/illustration/05.jpg";
import illustration6 from "../assest/images/illustration/06.jpg";
import illustration7 from "../assest/images/illustration/07.jpg";
import illustration8 from "../assest/images/illustration/08.jpg";
import illustration9 from "../assest/images/illustration/09.jpg";
import illustration10 from "../assest/images/illustration/10.jpg";
import illustration11 from "../assest/images/illustration/11.jpg";
import illustration12 from "../assest/images/illustration/12.jpg";
import illustration13 from "../assest/images/illustration/13.jpg";
import illustration14 from "../assest/images/illustration/14.jpg";
import illustration15 from "../assest/images/illustration/15.jpg";
import illustration16 from "../assest/images/illustration/16.jpg";
import illustration17 from "../assest/images/illustration/17.jpg";
import illustration18 from "../assest/images/illustration/18.jpg";
import illustration19 from "../assest/images/illustration/19.jpg";
import illustration20 from "../assest/images/illustration/20.jpg";
import illustration21 from "../assest/images/illustration/21.jpg";
import illustration22 from "../assest/images/illustration/22.jpg";
import illustration23 from "../assest/images/illustration/23.jpg";
import illustration24 from "../assest/images/illustration/24.jpg";
import illustration25 from "../assest/images/illustration/25.jpg";
import illustration26 from "../assest/images/illustration/26.jpg";
import illustration27 from "../assest/images/illustration/27.jpg";
import illustration28 from "../assest/images/illustration/28.jpg";
import illustration29 from "../assest/images/illustration/29.jpg";
import illustration30 from "../assest/images/illustration/30.jpg";
import illustration31 from "../assest/images/illustration/31.jpg";
import illustration32 from "../assest/images/illustration/32.jpg";



import app1 from "../assest/images/apps/01.jpg";
import app2 from "../assest/images/apps/02.jpg";
import app3 from "../assest/images/apps/03.jpg";
import app4 from "../assest/images/apps/04.jpg";
import app5 from "../assest/images/apps/05.jpg";
import app6 from "../assest/images/apps/06.jpg";
import app7 from "../assest/images/apps/07.jpg";
import app8 from "../assest/images/apps/08.jpg";
import app9 from "../assest/images/apps/09.jpg";
import app10 from "../assest/images/apps/10.jpg";
import app11 from "../assest/images/apps/11.jpg";
import app12 from "../assest/images/apps/12.jpg";
import app13 from "../assest/images/apps/13.jpg";
import app14 from "../assest/images/apps/14.jpg";
import app15 from "../assest/images/apps/15.jpg";
import app16 from "../assest/images/apps/16.jpg";
import app17 from "../assest/images/apps/17.jpg";

export const valueData = [
    {
        id: 1,
        img: gif1,
    },
    {
        id: 2,
        img: gif2,
    },
    {
        id: 3,
        img: gif3,
    },
    {
        id: 4,
        img: gif4,
    },
    {
        id: 5,
        img: gif5,
    },
    {
        id: 6,
        img: gif6,
    },
    {
        id: 7,
        img: gif7,
    },
    {
        id: 8,
        img: gif8,
    },
    { id: 9, img: logo1 },
    { id: 10, img: logo2 },
    { id: 11, img: logo3 },
    { id: 12, img: logo4 },
    { id: 13, img: logo5 },
    { id: 14, img: logo6 },
    { id: 15, img: logo7 },
    { id: 16, img: logo8 },
    { id: 17, img: logo9 },
    { id: 18, img: logo10 },
    { id: 19, img: logo11 },
    { id: 20, img: logo12 },
    { id: 21, img: logo13 },
    { id: 22, img: logo14 },
    { id: 23, img: logo15 },
    { id: 24, img: logo16 },
    { id: 25, img: logo17 },
    { id: 26, img: logo18 },
    { id: 27, img: logo19 },
    { id: 28, img: logo20 },
    { id: 29, img: logo21 },
    { id: 30, img: logo22 },
    { id: 31, img: logo23 },
    { id: 32, img: logo24 },
    { id: 33, img: logo25 },
    { id: 34, img: logo26 },
    { id: 35, img: logo27 },
    { id: 36, img: logo28 },
    { id: 37, img: logo29 },
    { id: 38, img: logo30 },
    { id: 39, img: logo31 },
    { id: 40, img: logo32 },
    { id: 41, img: logo33 },
    { id: 42, img: logo34 },
    { id: 43, img: logo35 },
    { id: 44, img: logo36 },
    { id: 45, img: logo37 },
    { id: 46, img: logo38 },
    { id: 47, img: logo39 },
    { id: 48, img: logo40 },
    { id: 49, img: logo41 },
    { id: 50, img: logo42 },
    { id: 51, img: logo43 },
    { id: 52, img: logo44 },
    { id: 53, img: logo45 },
    { id: 54, img: logo46 },
    // { id: 55, img: logo47 },
    { id: 56, img: logo48 },
    { id: 57, img: logo49 },
    { id: 58, img: logo50 },
    { id: 59, img: logo51 },
    { id: 60, img: logo52 },
    { id: 61, img: logo53 },
    { id: 62, img: logo54 },
    { id: 63, img: logo55 },
    { id: 64, img: logo56 },
    { id: 65, img: logo57 },
    { id: 66, img: logo58 },
    { id: 67, img: logo59 },
    { id: 68, img: logo60 },
    { id: 69, img: logo61 },
    { id: 70, img: logo62 },
    { id: 71, img: logo63 },
    { id: 72, img: logo64 },
    { id: 73, img: logo65 },
    { id: 74, img: logo66 },
    { id: 75, img: logo67 },
    { id: 76, img: logo68 },
    { id: 77, img: logo69 },
    { id: 78, img: logo70 },
    { id: 79, img: logo71 },
    { id: 80, img: logo72 },
    { id: 81, img: logo73 },
    { id: 82, img: logo74 },
    { id: 83, img: logo75 },
    { id: 84, img: logo76 },
    { id: 85, img: logo78 }

]

export let webs=[
    { id: 1, img: web1 },
    { id: 2, img: web2 },
    { id: 3, img: web3 },
    { id: 4, img: web4 },
    { id: 5, img: web5 },
    { id: 6, img: web6 },
    { id: 7, img: web7 },
    { id: 8, img: web8 },
    { id: 9, img: web9 },
    { id: 10, img: web10 },
    { id: 11, img: web11 },
    { id: 13, img: web13 },
    { id: 14, img: web14 },
    { id: 15, img: web15 },
    { id: 16, img: web16 },
    { id: 17, img: web17 },
    { id: 18, img: web18 },
    { id: 19, img: web19 },
    { id: 20, img: web20 },
    { id: 21, img: web21 },
    { id: 22, img: web22 },
    { id: 23, img: web23 },
    { id: 24, img: web24 },
    { id: 25, img: web25 },
    { id: 26, img: web26 },
    { id: 27, img: web27 },
    { id: 28, img: web28 },
    { id: 29, img: web29 },
    { id: 30, img: web30 },
    { id: 31, img: web31 },
    { id: 32, img: web32 },
    { id: 33, img: web33 },
    { id: 34, img: web34 },
    { id: 35, img: web35 },
    { id: 36, img: web36 },
    { id: 37, img: web37 },
    { id: 38, img: web38 },
    { id: 39, img: web39 },
    { id: 41, img: web41 },
    { id: 42, img: web42 },
    { id: 43, img: web43 },
    { id: 44, img: web44 },
    { id: 45, img: web45 },
    { id: 46, img: web46 },
    { id: 47, img: web47 },
    { id: 48, img: web48 },
    { id: 49, img: web49 }
];


export let videos=[
    {
      id: 1,
      videoImg: videoImg1,
      videoLink: videoLink1
    },
    {
      id: 2,
      videoImg: videoImg2,
      videoLink: videoLink2
    },
    {
      id: 3,
      videoImg: videoImg3,
      videoLink: videoLink3
    },
    {
      id: 4,
      videoImg: videoImg4,
      videoLink: videoLink4
    },
    {
      id: 5,
      videoImg: videoImg5,
      videoLink: videoLink5
    },
    {
      id: 6,
      videoImg: videoImg6,
      videoLink: videoLink6
    },
    {
      id: 7,
      videoImg: videoImg7,
      videoLink: videoLink7
    },
    {
      id: 8,
      videoImg: videoImg8,
      videoLink: videoLink8
    },
    {
      id: 9,
      videoImg: videoImg10,
      videoLink: videoLink10
    },
  ];

  export const stationary=[
    {
      id: 1,
      img: stationary1
    },
    {
      id: 2,
      img: stationary2
    },
    {
      id: 3,
      img: stationary3
    },
    {
      id: 4,
      img: stationary4
    },
    {
      id: 5,
      img: stationary5
    },
    {
      id: 6,
      img: stationary6
    },
    {
      id: 7,
      img: stationary7
    },
    {
      id: 8,
      img: stationary8
    },
    {
      id: 9,
      img: stationary9
    }
  ];

  export const illustration=[
    {
      id: 1,
      img: illustration1
    },
    {
      id: 2,
      img: illustration2
    },
    {
      id: 3,
      img: illustration3
    },
    {
      id: 4,
      img: illustration4
    },
    {
      id: 5,
      img: illustration5
    },
    {
      id: 6,
      img: illustration6
    },
    {
      id: 7,
      img: illustration7
    },
    {
      id: 8,
      img: illustration8
    },
    {
      id: 9,
      img: illustration9
    },
    {
      id: 10,
      img: illustration10
    },
    {
      id: 11,
      img: illustration11
    },
    {
      id: 12,
      img: illustration12
    },
    {
      id: 13,
      img: illustration13
    },
    {
      id: 14,
      img: illustration14
    },
    {
      id: 15,
      img: illustration15
    },
    {
      id: 16,
      img: illustration16
    },
    {
      id: 17,
      img: illustration17
    },
    {
      id: 18,
      img: illustration18
    },
    {
      id: 19,
      img: illustration19
    },
    {
      id: 20,
      img: illustration20
    },
    {
      id: 21,
      img: illustration21
    },
    {
      id: 22,
      img: illustration22
    },
    {
      id: 23,
      img: illustration23
    },
    {
      id: 24,
      img: illustration24
    },
    {
      id: 25,
      img: illustration25
    },
    {
      id: 26,
      img: illustration26
    },
    {
      id: 27,
      img: illustration27
    },
    {
      id: 28,
      img: illustration28
    },
    {
      id: 29,
      img: illustration29
    },
    {
      id: 30,
      img: illustration30
    },
    {
      id: 31,
      img: illustration31
    },
    {
      id: 32,
      img: illustration32
    }
  ];

  export const apps=[
    {
      id: 1,
      img: app1
    },
    {
      id: 2,
      img: app2
    },
    {
      id: 3,
      img: app3
    },
    {
      id: 4,
      img: app4
    },
    {
      id: 5,
      img: app5
    },
    {
      id: 6,
      img: app6
    },
    {
      id: 7,
      img: app7
    },
    {
      id: 8,
      img: app8
    },
    {
      id: 9,
      img: app9
    },
    {
      id: 10,
      img: app10
    },
    {
      id: 11,
      img: app11
    },
    {
      id: 12,
      img: app12
    },
    {
      id: 13,
      img: app13
    },
    {
      id: 14,
      img: app14
    },
    {
      id: 15,
      img: app15
    },
    {
      id: 16,
      img: app16
    },
    {
      id: 17,
      img: app17
    }
  ];