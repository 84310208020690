import React from 'react';
import combo_pack_1 from "../../../assest/images/combo-pack-1.jpg"
import combo_pack_2 from "../../../assest/images/combo-pack-2.png"
import combo_pack_3 from "../../../assest/images/combo-logos.png"

export const Branding = () => {
  return (
    <div>
        <section class="combo-pack">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <img src={combo_pack_1} class="branding" alt=""/>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-3">
                        <ul>
                            <li class="heading">LOGO</li>
                            <li>Unlimited Concepts</li>
                            <li>Unlimited Revisions</li>
                            <li class="heading">WEBSITE</li>
                            <li>2 Concepts</li>
                            <li>Unlimited Pages</li>
                            <li class="heading">STATIONERY</li>
                            <li>3 Concepts</li>
                            <li>Unlimited Revisions</li>
                            <li class="heading">BANNERS</li>
                            <li>2 Concepts of any Standard Size</li>
                            <li>Unlimited Revisions</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                    <img src={combo_pack_2} class="combo-price" alt=""/>
                    </div>
                    <div class="col-md-3">
                    <ul>
                        <li class="heading">BROCHURE</li>
                        <li>3 Concepts with Unlimited Color Options</li>
                        <li>Unlimited Revisions</li>
                        <li class="heading">SOCIAL MEDIA DESIGN</li>
                        <li>Any 3 of Your Choice</li>
                        <li>(Facebook, LinkedIn,</li>
                        <li>Twitter, YouTube)</li>
                    </ul>
                        <a href="javascript:;" data-fancybox data-src="#popupform" class="openpopup various btn btn-blue">Start Project</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="combo-logos">
            <img src={combo_pack_3} alt="combo_pack_3"/>
        </div>
    </div>
</section>
    </div>
  )
}
