import React from 'react';

export const CustomDesign = () => {
  return (
    <div>
      <section className="custom-design">
         <div className="container">
            <div className="text-center">
               <h6>professional</h6>
               <h2>Custom Logo Design Company</h2>
               <p>Goto Logo Experts is a premier custom logo design company established with the goal to bring brands at the top charts by creating awe-inspiring personalities. With our massive logo design online services, we shape the brand identities by incorporating unique aspects and core ideology.
                  We opt for incredible ideas and patterns that can help us reshape the brand identity and improve its clientele. We know the tactics to build a
                  prosperous and prospective lead generation funnel; therefore, we promise cheap and affordable logo creation services.
               </p>

               <div className="moreContnt" style={{display: 'none'}}>
                  <h4>Resonating excellence we are a logo design firm that stands firm to its policies and deliver unbeatable level of expertise.</h4>
                  <p>Goto Logo Experts is a firm with a team of experienced and competent logo designer who puts every last effort to delivering unmatched logo creation services. We have been a part of the logo design online industry for more than a decade, know all the secrets to drawing huge traffic, and accelerated conversions. With immaculate graphic designing, we leave no stone unturned to create such affordable logo designs online that can entice the target audience. From cuts to patterns, graphics to color tone, everything revolve around shaping a successful and engaging brand personality as part of our business logo design services. For us, achieving 100% customer satisfaction is the ultimate target and we spare no chance to fulfill our goal. Our logo designers spend hours producing cheap logo designs that can maximize your online visibility.</p>
                  <p>Moreover, according to our affordable business logo design packages, we provide a high definition logo with unique patterns and design. We create files in the desired formats and stick to following our customer needs. When working with the best logo design company you feel free to buy a custom logo design with a creative aspect. You get to witness brilliance wrapped in unseen creativity. We trigger a need to have your service among our target audience and make them trust in your company. With a height of professional attitude and unparalleled expertise, we produce brand images that catch the limelight.</p>
                  <p>Just when the need to buy a custom and affordable business logo design pops up in your head, get in touch with our professional logo designers. We at the leading logo design company promise to fulfil your unique needs and to deliver exceptionally hassle-free services. We will incorporate incredible patterns and elements to create a remarkably professional logo designs. We will gather your project brief, learn about your target audience and in an affordable logo design package, we will deliver a high definition logo. With appealing aesthetics, aligned topography and strategic approaches are design beam with an unmatched spark of creativity. So, without further ado, lets discuss your project brief and get down to the creation of a great logo for your brand’s ultimate success.</p>
               </div>
               <a href="javascript:;" className="btn btn-grey">Read More</a>
            </div>
         </div>
      </section>
    </div>
  )
}
