import React from 'react';
import test_img_1 from "../../../assest/images/tes-1.png";
import test_img_2 from "../../../assest/images/tes-2.png";
import test_img_3 from "../../../assest/images/tes-3.png";
// import test_img_4 from "../../../assest/images/tes-1.png";
import Slider from "react-slick";


export const Reviews = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      };
  return (
    <div>
        <section class="testimonial">
    <div class="container">
        <h6>Client Reviews</h6>
        <h2>What our clients say!</h2>
        <div class="owl-testimonial owl-carousel owl-theme testimonal-section main_testimonal">
            <Slider {...settings}>
            <div class="item">
                <img src={test_img_1} alt="test_img_1"/>
                <p>When it to diversity, enthusiasm and creativity – I can assure you that there is no one better than Go To Logo Experts. Thank you for such inspirational designs.</p>
                <h5>Russell Bryant <span>Entrepreneur &nbsp;–&nbsp; Tech House</span></h5>
            </div>
            <div class="item">
                <img src={test_img_2} alt="test_img_2"/>
                <p>Designs for my project came out exactly as I wanted them to, thank you Go To Logo Experts! People at Go To Logo Experts listen, understand and deliver beyond ordinary.</p>
                <h5>Jason Green <span>Project Lead  &nbsp;–&nbsp; Marketing Agency</span></h5>
            </div>
            <div class="item">
                <img src={test_img_3} alt="test_img_3"/>
                <p>I had so much fun working with Go To Logo Experts – beautiful designs, timely delivery and I must say, exceptional web development skills. Can’t wait to use their services again.</p>
                <h5>Jessica Sandler <span>Head of Marketing </span></h5>
            </div>
            <div class="item">
                <img src={test_img_3} alt="test_img_3"/>
                <p>Some of the best promotion material designs I have ever come across. It was the first time I outsourced work for our company and the delivery was as precise and it could get!</p>
                <h5>George Isaacson <span>Creative Director  &nbsp;–&nbsp; Advertising Services</span></h5>
            </div>
            </Slider>
        </div>
    </div>
</section>
    </div>
  )
}
