import React from 'react'
import "./index.css"
import paypal from "../../assest/paypal.webp"
export const Footer = () => {
  return (
    <div className='footer_section'>
     <div className='data_footer'>
      <div className='nested_footer'>
        <p>Privacy | Terms of Use</p>
      </div>
      <div className='copywright'>
        <p>Copyright 2023 dummy name | All right reserved</p>
      </div>
      <div className='paypal'>
        <img src={paypal} alt='paypal' width={"auto"} height={"auto"}/>
      </div>
     </div>
    </div>
  )
}
