import React from "react";
import './App.css';

import {
  Route,
  Routes,
} from "react-router-dom";
// import { Home } from "./component/home";
import { LandingPage } from "./layout/router";
import { HomeComp } from "./layout/router/main/home";
import { WorkPortfolio } from "./layout/router/main/portfolio";
import { PricingPackages } from "./layout/router/main/packages";

function App() {

  return (
    <div>
     <Routes>
      <Route path="/" element={<HomeComp/>} />
      <Route path="/lp" element={<LandingPage/>} />
      <Route path="/portfolio" element={<WorkPortfolio/>}/>
      <Route path="/packages" element={<PricingPackages/>}/>
     </Routes>
    </div>
  );
}

export default App;
