import React from 'react';
import weTakeImg from "../../../assest/we-take-img.webp";


export const Concepts = () => {
  return (
    <div>
          <section className="we-take" data-aos="fade-down" data-aos-duration="1500">
        <div className="container">
            <div className="section-heading">
                <h3>We Take The Hassle Out of <br/>
                    Logo Designing</h3>
                <p>Our logo design process is simple. From understanding your brand and completely absorbing the brief,
                    to carrying out extensive <br/> research, and working out a unique design solution for your brand, to
                    the
                    ultimate submission of the logo design.
                </p>
            </div>
            <div className="row align-items-center">
                <div className="col-md-4 marigin-top">
                    <div className="we-take-wrpr text-right">
                        <h3>Share your Logo Idea</h3>
                        <p>Your vision matters, share it with us, and watch it transform into an exceptional logo.</p>
                    </div>
                    <div className="we-take-wrpr text-right mt-5">
                        <h3>Sign-up</h3>
                        <p className="mb-0">Join logo design journey with a simple sign-up and unlock endless creative
                            possibilities.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="we-take-wrpr text-center">
                        <h3>Initial Concepts</h3>
                        <p>We will design your logo concepts based on your vision.</p>
                    </div>
                    <img className=" " src={weTakeImg} alt='weTakeImg'/>
                </div>
                <div className="col-md-4 marigin-top">
                    <div className="we-take-wrpr text-left">
                        <h3>Revision</h3>
                        <p>Refine and perfect your logo with our collaborative approach to revisions.</p>
                    </div>
                    <div className="we-take-wrpr text-left mt-5">
                        <h3>Final Delivery</h3>
                        <p className="mb-0">Receive your captivating logo, ready to make a lasting impression on your
                            audience.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
