import React from 'react'
import img1 from "../../../assest/awards.webp";
import Slider from "react-slick";

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

export const Cards = () => {
  return (
    <div>
        <section className="combo-sec" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
            <div className="top-packages">
                <div className="combo-slider owl-carousel owl-theme">
                <Slider {...settings}>

                    <div className="item package-box packagebox">
                        <div className="bg-secondary">
                            <div className="row pec-name">
                                <div className="col-md-8">
                                    <h1 className="title">Logo Infinite</h1>
                                    <div className="combo-list-style">
                                        <ul>
                                            <li> Unlimited Original Logo Concepts</li>
                                            <li> 8 Dedicated Logo Designer (Industry Specific)</li>
                                            <li> Unlimited Revisions</li>
                                            <li> 3 Page Basic Website</li>
                                            <li> Stationery Design (Business Card, Letterhead, Envelope)</li>
                                            <li> Brand Book</li>
                                            <li> 500 Business Cards </li>
                                            <li> 500 Letterheads</li>
                                            <li> Email Signature Design</li>
                                            <li> Social Media Designs (Facebook, Twitter, Instagram)</li>
                                            <li> 2 Sided Flyer OR Bi-Fold Brochure Design</li>
                                            <li> With Grey Scale Format</li>
                                            <li> Free Icon Design</li>
                                            <li> Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                            <li> 24 - 48 Hours Turn Around Time</li>
                                            <li className="features">MORE FEATURES</li>
                                            <li> 100% Satisfaction</li>
                                            <li> 100% Ownership Rights</li>
                                            <li> Money Back Guarantee</li>
                                            <li> Dedicated Account Manager</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 has-pad">
                                    <div className="final price">
                                        <h4 className="ult-cut-prc"><span className="inner">$2663</span></h4>
                                        <h3>$799</h3>
                                        <a href="javascript:void(0);" className="btn mt-3 bnr-btn hvr-buzz-out openpopup">Order
                                            Now</a>
                                    </div>
                                    <figure>
                                        <img className="  m-auto"  src={img1} alt="img_1" width={"auto"} height={"auto"}/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item package-box packagebox">
                        <div className="bg-secondary">
                            <div className="row pec-name">
                                <div className="col-md-8">
                                    <h1 className="title">Logo Combo</h1>
                                    <div className="combo-list-style">
                                        <ul>
                                            <li>Unlimited Original Logo Concepts</li>
                                            <li>12 Dedicated Logo Designer (Industry Specific)</li>
                                            <li>Unlimited Revisions</li>
                                            <li>7 Page Informative Website</li>
                                            <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
                                            <li>Brand Book</li>
                                            <li>500 Business Cards </li>
                                            <li>500 Letterheads</li>
                                            <li>Email Signature Design</li>
                                            <li>Social Media Designs (Facebook, Twitter, Instagram)</li>
                                            <li>2 Sided Flyer OR Bi-Fold Brochure Design</li>
                                            <li>With Grey Scale Format</li>
                                            <li>Free Icon Design</li>
                                            <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                            <li>24 - 48 Hours Turn Around Time</li>
                                            <li className="features">MORE FEATURES</li>
                                            <li>100% Satisfaction</li>
                                            <li>100% Ownership Rights</li>
                                            <li>Money Back Guarantee</li>
                                            <li>Dedicated Account Manager</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 has-pad">
                                    <div className="final price">
                                        <h4 className="ult-cut-prc"><span className="inner">$3330</span></h4>
                                        <h3>$999</h3>
                                        <a href="javascript:void(0);" className="btn bnr-btn mt-3 hvr-buzz-out openpopup">Order
                                            Now</a>
                                    </div>
                                    <figure>
                                        <img className="  m-auto"  src={img1} alt="img_1" width={"auto"} height={"auto"}/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
