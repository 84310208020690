import React from 'react';
import color_logo from "../../assest/png/logo.png";

export const Header = () => {
  return (
    <div>
         <header className="main-header">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="2000"><a href="/">
                  <img className=" " src={color_logo} alt='color_logo' width={"auto"} height={"auto"}/></a>
                  </div>
                <div className="col-md-9 text-right" data-aos="fade-left" data-aos-duration="2000">
                    <div className="header-button d-flex justify-content-end">
                        <a href="te:1234567897" className="hvr-buzz-out dft-btn">
                            1234567897
                        </a>
                        <a href="javascript:void(0);" className="chat hvr-buzz-out dft-btn">
                            Live Chat
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </div>
  )
}
