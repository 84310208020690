import React from 'react';
import bn1 from "../../../assest/bnr-icon1.webp"
import bn2 from "../../../assest/bnr-icon2.webp"
import bn3 from "../../../assest/bnr-icon3.webp"
import bn4 from "../../../assest/bnr-icon4.webp"
import bn5 from "../../../assest/bnr-icon5.webp";

export const Service = () => {
  return (
    <div>
         <section className="services-sec" data-aos="fade-down" data-aos-duration="1500">
        <div className="container">
            <ul className="services-icons our-work-slider">
                <li>
                    <img className=" " src={bn1} alt='bnt' width={"auto"} height={"auto"}/>
                    <h6>100% Satisfaction <br/>
                        Guaranteed</h6>
                </li>
                <li>
                    <img className=" " src={bn2} alt='bnt' width={"auto"} height={"auto"}/>
                    <h6>24 X 7 Customer <br/>
                        Support</h6>
                </li>
                <li>
                    <img className=" " src={bn3} alt='bnt' width={"auto"} height={"auto"}/>
                    <h6>100% Ownership <br/>
                        Rights</h6>
                </li>
                <li>
                    <img className=" " src={bn4} alt='bnt' width={"auto"} height={"auto"}/>
                    <h6>Money Back<br/>
                        Guarantee</h6>
                </li>
                <li>
                    <img className=" " src={bn5} alt='bnt' width={"auto"} height={"auto"}/>
                    <h6>Industry Specific <br/>
                        Designers</h6>
                </li>
            </ul>
        </div>
    </section>
    </div>
  )
}
