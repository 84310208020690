import React from 'react';
import test_img from "../../../assest/testi-img1.webp";
import test_img2 from "../../../assest/testi-img2.webp";
import testing_img3 from "../../../assest/testi-img3.webp";
import Slider from "react-slick";


var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
export const Client = () => {
  return (
    <div>
         <section className="testimonials-sec" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
            <div className="section-heading">
                <h3>Our Clientele</h3>
                <p>Client’s Appreciations is the fuel that keeps us leading.</p>
            </div>
            <div className="testimonials-slider owl-carousel owl-theme">
                <Slider {...settings}>
                <div className="item">
                    <div className="testimonials-area">
                        <img className=" " src={test_img2} alt='img_tesr_2' width={"auto"} height={"auto"}/>
                        <div className="test-contnet">
                            <h3>Andre Conner</h3>

                            <p> I had an incredible experience working with dummy name! From the initial
                                consultation to the final delivery, they displayed utmost professionalism and
                                creativity. Their team took the time to understand my brand's personality and values,
                                and the logo they designed exceeded my expectations.
                            </p>
                            <div className="star">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="testimonials-area">
                        <img className=" " src={test_img} alt='test_img' width={"auto"} height={"auto"}/>
                        <div className="test-contnet">
                            <h3>Tony Barnett</h3>

                            <p>Wow! dummy name truly lived up to their name. They guided me through the
                                entire logo design process and were incredibly patient with my requests and revisions. I
                                was amazed at their ability to transform my vague ideas into a beautiful, eye-catching
                                logo that represents my business impeccably.
                            </p>
                            <div className="star">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="testimonials-area">
                        <img className=" " src={testing_img3} alt='testing_img3' width={"auto"} height={"auto"}/>
                        <div className="test-contnet">
                            <h3>Alexander George</h3>

                            <p>I am beyond thrilled with the exceptional service provided by dummy name.
                                Right from the start, they showed an impressive level of dedication and commitment to
                                crafting a logo that perfectly encapsulates my brand's identity.With their expertise and
                                attention to detail, they turned my vision into a reality, and I couldn't be happier
                                with the final result!
                            </p>
                            <div className="star">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
                </Slider>
            </div>
        </div>
    </section>
        </div>
  )
}
